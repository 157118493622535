<template>
  <UiPage
    :title="$t('app.page.support')"
    :titleTemplate="$t('app.page.support')"
  >
    <template #top>
      <BackButton />
    </template>

    <template #content>
      <div class="tabs">
        <div class="tabs-list">
          <TabButton
            :text="$t('views.default.support.all-qestions')"
            :value="'all'"
            :active="activeTab"
            @click="activeTab = 'all'"
          />

          <TabButton
            v-for="item in data"
            :key="item.id"
            :text="item.name"
            :value="item.id"
            :active="activeTab"
            @click="activeTab = item.id"
          />
        </div>
      </div>

      <div class="content">
        <div class="content__list">
          <template
            v-for="item in data"
          >
            <div
              v-if="
                activeTab === 'all'
                  || activeTab === item.id
              "
              :key="item.id"
              class="list__item"
            >
              <h2>
                {{ item.name }}
              </h2>

              <template
                v-for="quest in item.faqs"
                :key="quest.id"
              >
                <div
                  v-if="quest.tag_ids.indexOf(String(activeTab)) !== -1 || activeTab === 'all'"
                  class="content__list__item"
                  @click="activeQuests !== quest.id ? activeQuests = quest.id : activeQuests = null"
                >
                  <div
                    class="content__list__item__top"
                    :class="{
                      active: quest.id === activeQuests
                    }"
                  >
                    {{ quest.name }}

                    <svg
                      width="14" height="9"
                      style="min-width: 14px; min-height: 9px;"
                      viewBox="0 0 14 9" fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 8L7 2L13 8" stroke="#787C8D" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>

                  <div
                    v-show="quest.id === activeQuests"
                    v-html="quest.text"
                    class="content__list__item__content"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>

        <div class="content__sticky">
          <div class="content__sticky__info">
            <svg
              width="60" height="60"
              style="min-width: 60px; min-height: 60px;"
              viewBox="0 0 60 60" fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M15 0H30V15L15 30H0V15L15 0Z" fill="#2F57E9" fill-opacity="0.2"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M30 30H15L0 45V60H15L30 45V60H45L60 45V30H45L60 15V0H45L30 15V30ZM30 30H45L30 45V30Z" fill="#2F57E9" fill-opacity="0.2"/>
              <g clip-path="url(#clip0_973_16004)">
                <path d="M31.6173 40.6678L23.7891 48.4726C23.5548 48.6132 23.2501 48.9648 22.6172 48.7773C22.2891 48.6601 21.8906 48.285 21.8204 47.8632V47.8398L19.5703 34.3866L21.3985 33.0039C21.6352 33.1427 26.1527 35.7917 25.875 35.6289L31.6172 39.0039C32.0626 39.4726 32.0626 40.1991 31.6173 40.6678Z" fill="#2F57E9"/>
                <path d="M31.6173 40.6678L23.7891 48.4726C23.5548 48.6132 23.2501 48.9648 22.6172 48.7773C22.2891 48.6601 21.8906 48.285 21.8204 47.8632V47.8398L19.5703 34.3866L21.3985 33.0039C21.6352 33.1427 26.1527 35.7917 25.875 35.6289L31.6172 39.0039C32.0626 39.4726 32.0626 40.1991 31.6173 40.6678Z" fill="black" fill-opacity="0.3"/>
                <path d="M49.9772 12.5748L42.8672 47.8867C42.7969 48.2617 42.5156 48.5898 42.1406 48.7305C42 48.8008 41.8594 48.8242 41.7188 48.8242C41.4844 48.8242 41.25 48.7539 41.0391 48.6133C40.8033 48.4448 23.5343 36.1098 24.6328 36.8945C14.1766 31.7148 19.6307 34.4373 10.6562 29.957C10.2344 29.7461 10 29.3242 10 28.8789C10.0234 28.4101 10.2812 28.0117 10.7031 27.8242L48.36 11.2623C48.7584 11.0983 49.2272 11.1451 49.5553 11.4264C49.6022 11.4733 49.6725 11.5436 49.7194 11.5905C49.9538 11.8717 50.0475 12.2233 49.9772 12.5748Z" fill="#2F57E9"/>
                <path d="M49.9772 12.5748L42.8672 47.8867C42.7969 48.2617 42.5156 48.5898 42.1406 48.7305C42 48.8008 41.8594 48.8242 41.7188 48.8242C41.4844 48.8242 41.25 48.7539 41.0391 48.6133C40.8033 48.4448 23.5343 36.1098 24.6328 36.8945C14.1766 31.7148 19.6307 34.4373 10.6562 29.957C10.2344 29.7461 10 29.3242 10 28.8789C10.0234 28.4101 10.2812 28.0117 10.7031 27.8242L48.36 11.2623C48.7584 11.0983 49.2272 11.1451 49.5553 11.4264C49.6022 11.4733 49.6725 11.5436 49.7194 11.5905C49.9538 11.8717 50.0475 12.2233 49.9772 12.5748Z" fill="white" fill-opacity="0.4"/>
                <path d="M49.7194 11.5908L25.875 35.6293L24.6329 36.8948L22.6172 48.7776C22.2891 48.6604 21.8906 48.2853 21.8204 47.8635V47.8401L19.5703 34.387L21.3985 33.0043L49.7194 11.5908Z" fill="#2F57E9"/>
              </g>
              <defs>
                <clipPath id="clip0_973_16004">
                  <rect width="40" height="40" fill="white" transform="translate(10 10)"/>
                </clipPath>
              </defs>
            </svg>

            <p class="time">
              {{ $t('views.default.support.daily') }}
            </p>

            <p class="label">
              {{ $t('views.default.support.online') }}
            </p>

            <BlueButton
              :text="$t('views.default.support.follow')"
              tag="a"
              href="https://t.me/OnliX_support_bot"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </template>
  </UiPage>
</template>

<script>
import UiPage from '@/ui/Page.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import TabButton from '@/ui/buttons/TabButton.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    UiPage,
    BackButton,
    TabButton,
    BlueButton
  },
  data () {
    return {
      mountFetch: false,
      activeTab: 'all',
      activeQuests: null,
      data: []
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          '/shared/faq')

        if (resp.status === 200) {
          this.data = resp.data.data
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.mountFetch = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  margin-bottom: 30px;

  .tabs-list {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    overflow-x: auto;

    button {
      margin-bottom: 5px;
    }
  }
}

.content {
  display: flex;

  .content__list {
    width: 100%;
    margin-right: 40px;

    h2 {
      margin-bottom: 30px;
      font-size: 26px;
      font-family: Medium;
    }

    .list__item {
      margin-bottom: 40px;
    }

    .content__list__item {
      width: 100%;
      margin-bottom: 10px;
      padding: 20px;
      background: white;
      border-radius: 15px;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
      cursor: pointer;

      .content__list__item__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        line-height: 100%;
        color: rgb(var(--color-dark));
        font-family: Medium;

        &.active {
          color: rgba(var(--text-color-light-gray));

          svg {
            transform: rotate(0deg);
          }
        }

        svg {
          transform: rotate(180deg);
        }
      }

      .content__list__item__content {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #9CA3B8;
        font-size: 16px;
        line-height: 180%;
        color: rgb(var(--color-dark));
      }
    }
  }

  .content__sticky {
    position: relative;

    .content__sticky__info {
      position: sticky;
      top: 20px;
      min-width: 295px;
      padding: 20px;
      background: white;
      border-radius: 15px;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);

      p {
        &.time {
          margin: 20px 0 0 0;
          font-size: 14px;
          line-height: 100%;
          color: rgba(var(--text-color-light-gray));
          font-family: Medium;
        }

        &.label {
          margin: 9px 0 20px 0;
          font-size: 16px;
          line-height: 100%;
          color: rgb(var(--color-dark));
          font-family: Medium;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .content {
    flex-direction: column;

    .content__list {
      margin-right: 0;
    }

    .content__sticky {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .tabs {
    position: relative;
    margin-bottom: 15px;
    padding: 0 0 10px;
    height: 37px;
    overflow: hidden;

    .tabs-list {
      height: 45px;
      width: 100%;
      padding: 0 17px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-color: rgba(var(--primary-color), 0) rgba(var(--primary-color), 0);
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
        background-color: rgba(var(--primary-color), 0);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(var(--primary-color), 1);
        border-radius: 4px;
      }

      button {
        margin-bottom: 5px;
        white-space: nowrap;
      }
    }
  }

  .content {
    padding: 0 17px;
    flex-direction: column;

    .content__list {
      margin-right: 0;

      h2 {
        margin-bottom: 20px;
      }

      .list__item {
        margin-bottom: 30px;
      }

      .content__list__item {
        padding: 20px 15px;
      }
    }

    .content__sticky {
      position: relative;
      margin-top: 20px;

      .content__sticky__info {
        position: static;
        padding: 20px 15px;
      }
    }
  }
}
</style>
